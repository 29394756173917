import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { BootstrapVue3 } from 'bootstrap-vue-3'
import App from './App.vue'
import router from './router/index.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import 'viewerjs/dist/viewer.css'
import VueEasyLightbox from 'vue-easy-lightbox';


const app = createApp(App)
app.use(createPinia())
app.use(router)
app.use(BootstrapVue3);
app.use(VueEasyLightbox)
app.mount('#app')

