<template>
    <section>
        <div v-if="(this.connectionSpeed.isBadConnection === false || this.connectionSpeed.isNormalConnection === true || this.connectionSpeed.isFastConnection === true)">
            <div class="container py-5">
                <div class="preview-image-container row flex-fill fill d-sm-flex">
                    <div class="col-6 col-md-4 col-xl-3 px-0 py-0 mx-auto my-1 my-sm-2" v-for="(image, index) in this.selectedSubCategoryObject" :key="index">
                        <div class="image-wrap text-center align-middle">
                            <div class="px-1 px-sm-2">
                                <img :src="image.subimages[0]" class="w-100"
                                      @click="imageClick(image)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="container py-5">
                <div class="preview-image-container row flex-fill fill d-sm-flex">
                    <div class="col-6 col-md-4 col-xl-3 px-0 py-0 mx-auto my-1 my-sm-2" v-for="(image, index) in this.selectedSubCategoryObject" :key="index">
                        <div class="image-wrap text-center align-middle">
                            <div class="px-1 px-sm-2">
                                <img :src="image.subimagesSlow[0]" class="w-100"
                                     @click="imageClick(image)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        selectedSubCategoryObject: Object,
        connectionSpeed: null,
    },
    data() {
        return {
            images: [],
        }
    },
    methods: {
        imageClick(image) {
            this.$emit('image-clicked', image);
        },
    },
};
</script>

<style>
@import '@/assets/portfolio.css';
</style>