<template>
    <div class="container py-5">
        <div class="row flex-fill fill d-sm-flex form-row">
            <div class="col-6 col-md-4 col-xl-3 px-0 py-0 mx-auto my-1 my-sm-2">
                <form @submit.prevent>
                    <div class="auth_form_head_content">
                        <p>Enter password</p>
                    </div>
                    <div class="form-outline mb-4">
                        <input
                            type="password"
                            id="form2Example2"
                            class="form-control password-input"
                            v-model="password"
                            @input="validPassword = true"
                            placeholder="Password"
                        >
                    </div>
                    <div v-if="!validPassword">
                        <p class="alert alert-danger">Invalid password</p>
                    </div>
                    <button type="submit" id="signInBtn" class="btn btn-outline-success" ref="signInButton">
                        Sign in
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return{
            password: '',
            validPassword: true,
        }
    },
    methods: {
        async checkPassword() {
            try {
                const response = await axios.post(`https://back.art.pinglestudio.com/old/view/?password=${this.password}`);
                if (response.status === 200) {
                    this.$router.push({ name: 'Gallery Home' });
                    const password = this.password;
                    localStorage.setItem('password', password);
                }
            } catch (error) {
                this.validPassword = false;
                console.error('Помилка з\'єднання з сервером');
            }
        },
    },
    mounted() {
        this.$refs.signInButton.addEventListener('click', this.checkPassword)
    },
}
</script>

<style>
@import '@/assets/portfolio.css';
</style>