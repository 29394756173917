<template>
    <section>
        <div class="container py-5">
            <div class="preview-image-container row flex-fill fill d-sm-flex">
                <div
                    class="col-6 col-md-4 col-xl-3 px-0 py-0 mx-auto my-1 my-sm-2"
                    v-for="(getSubCategoryName, subCategoryName) in this.selectedCategoryObject"
                    :key="subCategoryName.subCategoryName"
                >
                    <div class="image-wrap text-center align-middle">
                        <div class="px-1 px-sm-2">
                            <img
                                :src="getSubCategoryName.url"
                                class="w-100"
                                @click="imageClicked(getSubCategoryName.subCategoryName)"
                            />
                            <p class="title-page-images-text">{{ getSubCategoryName.subCategoryName }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        selectedCategoryObject: Object,
    },
    data() {
        return {
            images: [],
        }
    },
    methods: {
        imageClicked(subCategoryName) {
            this.$emit('image-clicked', subCategoryName);
        },
    },
};
</script>

<style>
@import '@/assets/portfolio.css';
</style>