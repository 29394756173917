<template>
    <div id="App" class="">
        <nav class="navbar navbar-dark">
            <div class="container-fluid">
                <div class="d-flex align-items-center">
                    <img src="https://art.pinglestudio.com/wp-content/themes/pingle/images/logo-pingle-small.png" width="140" height="50" style="margin-right: 10px;">
                    <button
                        id="BurgerMenuButton"
                        class="navbar-toggler"
                        type="button"
                        @click="toggleNavbar"
                        :aria-expanded="navbarOpen ? 'true' : 'false'"
                        aria-label="Toggle navigation"
                    >
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div v-if="galleryObjectGenerated">
                        <ol class="breadcrumb ms-3 my-0 py-0 mx-0 px-0 pl-1">
                            <li class="breadcrumb-item"><a
                                @click="homePage()"
                                style="cursor: pointer"
                            >
                                Artworks
                            </a>
                            </li>
                            <li class="breadcrumb-item">
                                <a
                                    @click="generateGalleryImageObject(null, categoryNameBySelectedSubCategory)"
                                    style="cursor: pointer"
                                >
                                    {{ categoryNameBySelectedSubCategory }}
                                </a>
                            </li>
                            <li class="breadcrumb-item"><a href="#">{{ selectedSubCategoryName }}</a></li>
                        </ol>
                    </div>
                    <div v-else class="d-flex align-items-center order-md-2" aria-label="breadcrumb">
                        <ol class="breadcrumb ms-3 my-0 py-0 mx-0 px-0 pl-1">
                            <li class="breadcrumb-item"><a @click="homePage()"
                                                           style="cursor: pointer">Artworks</a></li>
                        </ol>
                    </div>
                </div>
                <div class="d-flex" role="search">
                    <button v-if="isUserAdmin"
                            type="button"
                            class="btn btn-outline-warning"
                            data-mdb-ripple-color="dark"
                            @isUserAdmin="isUserAdmin"
                            @click="redirectToAdminPanel()"
                    >
                        Admin Panel
                    </button>
                </div>
            </div>
        </nav>
        <nav v-if="navbarOpen" class="navbar navbar-dark fixed-left px-1 px-sm-3 py-1 py-sm-2">
            <div class="collapse navbar-collapse" id="NavBar" :class="{ 'show': navbarOpen }">
                <ul class="navbar-nav" style="margin-top: 10px">
                    <li><a href="https://art.pinglestudio.com/">Home</a></li>
                    <li class="nav-item dropdown">
                        <div>
                            <b-dropdown
                                v-for="uniqueCategory in uniqueCategories"
                                :key="uniqueCategory"
                                size="sm"
                                split
                                :text="uniqueCategory"
                                class="dropdown-button"
                                style="display: block;"
                                variant="background-color: transparent;"
                                @click="generateGalleryImageObject(null, uniqueCategory)"
                            >
                                <b-dropdown-item v-for="subCategory in getSubCategoriesForCategory(uniqueCategory)" :key="subCategory.subCategoryName">
                                    <a @click="generateGalleryImageObject(subCategory.subCategoryName)" style="cursor: pointer">
                                        {{ subCategory.subCategoryName }}
                                    </a>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
        <div v-if="selectedSubCategoryObject == null && selectedCategoryObject == null">
            <div class="container py-5">
                <div class="preview-image-container row flex-fill fill d-sm-flex">
                    <div
                        class="col-6 col-md-4 col-xl-3 px-0 py-0 mx-auto my-1 my-sm-2"
                        v-for="(url, subCategoryName) in getSubCategoryTitleImageObj"
                        :key="subCategoryName"
                    >
                        <div class="image-wrap text-center align-middle">
                            <div class="px-1 px-sm-2">
                                <img
                                    :src="url"
                                    class="w-100"
                                    @click="generateGalleryImageObject(subCategoryName)"
                                />
                                <p class="title-page-images-text">{{ subCategoryName }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="selectedSubCategoryObject == null && selectedCategoryObject != null">
            <SelectedCategoryGalleryPreview
                :selectedCategoryObject="selectedCategoryObject"
                :connectionSpeed="this.connection"
                @image-clicked="generateGalleryImageObject"
            />
        </div>
        <div v-else>
            <SelectedSubCategoryGalleryPreview
                :selectedSubCategoryObject="selectedSubCategoryObject"
                :connectionSpeed="this.connection"
                @image-clicked="showOverlay"
            />
            <vue-easy-lightbox
                id="lightbox"
                class="lightbox-background"
                :visible="lightboxVisible"
                :imgs="lightboxImages"
                :index="lightboxIndex"
                @hide="hideLightbox"
            >
                <template v-slot:toolbar>
                    <div class="custom-lightbox-toolbar">
                        <div
                            v-for="(image, index) in lightboxImages"
                            :key="index"
                            :class="['lightbox-thumbnail', { 'active': index === activeLightboxIndex }]"
                            @click="changeLightboxIndex(index)"
                        >
                            <img :src="image.src" alt="Thumbnail">
                        </div>
                    </div>
                </template>
            </vue-easy-lightbox>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import SelectedSubCategoryGalleryPreview from "@/components/SelectedSubCategoryGalleryPreview.vue";
import SelectedCategoryGalleryPreview from "@/components/SelectedCategoryGalleryPreview.vue";
import VueEasyLightbox from 'vue-easy-lightbox';
import {GalleryImageObject} from "@/components/jsFunctions/GalleryImage";

export default {
    components: {
        SelectedSubCategoryGalleryPreview,
        SelectedCategoryGalleryPreview,
        VueEasyLightbox,
    },
    props: {
        subCategories: Object,
    },
    data() {
        return {
            isUserAdmin: false,
            images: {},
            connection:  this.MeasureConnectionSpeed(),
            navbarOpen: false,
            selectedSubCategoryName: null,
            categoryNameBySelectedSubCategory: null,
            selectedSubCategoryObject: null,
            selectedCategoryObject: null,
            selectedSubCategoryImagesPreview: null,
            imageObjectFromSelectedCategoryGalleryComponent: null,
            lightboxImages: [],
            lightboxVisible: false,
            lightboxIndex: 0,
            activeLightboxIndex: 0,
            getSubCategoryTitleImageObj: {},
            subCategoriesImagesPreview: {},
            galleryObjectGenerated: false,
        };
    },
    created() {
        this.MeasureConnectionSpeed().then(connection => {
            this.connection = connection;
        }).catch(error => {
            console.error(error);
        });
        const password = localStorage.getItem("password");
        if (password) {
            this.getImagesList(password);
        }
        window.addEventListener('beforeunload', function() {
            localStorage.clear();
        });
    },
    computed: {
        uniqueCategories() {
            const categoriesSet = new Set();
            for (const categoryName in this.images) {
                categoriesSet.add(this.images[categoryName].categoryName);
            }
            return Array.from(categoriesSet);
        },
    },
    methods: {
        getImagesList(password) {
            const path = `https://back.art.pinglestudio.com/old/view/?password=${password}`;
            axios.get(path)
                .then(response => {
                    this.images = response.data.data;
                    this.isUserAdmin = response.data.isUserAdmin;
                    if (this.connection.isBadConnection === true && this.connection.isNormalConnection !== true && this.connection.isFastConnection !== true) {
                        for (let categoryName in this.images) {
                            if (Object.prototype.hasOwnProperty.call(this.images, categoryName)) {
                                this.activeCategoryValue = this.images[categoryName].categoryName;
                                break;
                            }
                        }
                        for (const groupCategory in this.images) {
                            const categoryObj = this.images[groupCategory];
                            const url = categoryObj.urlSlow;
                            this.getSubCategoryTitleImageObj[categoryObj.subCategoryName] = url;
                        }
                    }
                    else {
                        for (let categoryName in this.images) {
                            if (Object.prototype.hasOwnProperty.call(this.images, categoryName)) {
                                this.activeCategoryValue = this.images[categoryName].categoryName;
                                break;
                            }
                        }
                        for (const groupCategory in this.images) {
                            const categoryObj = this.images[groupCategory];
                            const url = categoryObj.url;
                            this.getSubCategoryTitleImageObj[categoryObj.subCategoryName] = url;
                        }
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getSubCategoriesForCategory(categoryName) {
            return this.images.filter(category => category.categoryName === categoryName);
        },
        generateGalleryImageObject(subCategoryName = null, categoryName = null) {
            if (subCategoryName != null) {
                const imageGalleryObject = new GalleryImageObject(subCategoryName, null, this.images, this.connection);
                this.subCategoriesImagesPreview = imageGalleryObject.subCategoryImageObject.subCategoriesImagesPreview;
                this.selectedSubCategoryObject = this.subCategoriesImagesPreview;
                this.selectedSubCategoryName = imageGalleryObject.subCategoryImageObject.getSubCategoryName;
                this.categoryNameBySelectedSubCategory = imageGalleryObject.subCategoryImageObject.getCategoryName;
                this.galleryObjectGenerated = true;
                if (this.navbarOpen === true) {
                    this.toggleNavbar();
                }
            }
            else if (categoryName != null) {
                const imageGalleryObject = new GalleryImageObject(null, categoryName, this.images, this.connection);
                this.selectedCategoryObject = imageGalleryObject.categoryImageObject.subCategoryTitleImages;
                this.selectedSubCategoryObject = null;
                this.selectedSubCategoryName = null;
                this.categoryNameBySelectedSubCategory = categoryName;
                this.galleryObjectGenerated = true;
                if (this.navbarOpen === true) {
                    this.toggleNavbar();
                }
            }
        },
        homePage() {
            this.selectedCategoryObject = null;
            this.selectedSubCategoryName = null;
            this.selectedSubCategoryObject = null;
            this.categoryNameBySelectedSubCategory = null;
            this.galleryObjectGenerated = false;
            if (this.navbarOpen === true) {
                this.toggleNavbar();
            }
        },
        showOverlay(image) {
            this.lightboxImages = this.prepareLightboxImages(image);
            this.lightboxVisible = true;
            document.body.classList.add('no-scroll');
            this.toggleHtmlOverflow('hidden');
            this.imageObjectFromSelectedCategoryGalleryComponent = image;
        },
        hideLightbox() {
            this.lightboxVisible = false;
            document.body.classList.remove('no-scroll');
            this.toggleHtmlOverflow('auto');
            this.imageObjectFromSelectedCategoryGalleryComponent = null;
            this.lightboxIndex = 0;
            this.activeLightboxIndex = 0;
        },
        toggleHtmlOverflow(value) {
            document.documentElement.style.overflowY = value;
            document.body.style.overflowY = value;
        },
        prepareLightboxImages(image) {
            if (this.connection.isBadConnection === false || this.connection.isNormalConnection === true || this.connection.isFastConnection === true) {
                const lightboxImages = [];
                for (const subimage of image.subimages) {
                    lightboxImages.push({
                        title: image.title,
                        src: subimage
                    });
                }
                return lightboxImages;
            }
            else {
                const lightboxImages = [];
                for (const subimage of image.subimagesSlow) {
                    lightboxImages.push({
                        title: image.title,
                        src: subimage
                    });
                }
                return lightboxImages;
            }
        },
        changeLightboxIndex(index) {
            this.lightboxIndex = index;
            this.activeLightboxIndex = index;
        },
        toggleNavbar() {
            this.navbarOpen = !this.navbarOpen;
        },
        MeasureConnectionSpeed() {
            return new Promise((resolve, reject) => {
                if (navigator.connection) {
                    let connectionEffectiveType = navigator.connection.effectiveType;
                    let connectionType = navigator.connection.type;
                    let bandwidth = navigator.connection.downlink.toFixed(2);
                    let isBadConnection = connectionEffectiveType === '3g' || bandwidth < 2;
                    let isNormalConnection = connectionEffectiveType === '4g' || bandwidth >= 4;
                    let isFastConnection = connectionEffectiveType === '5g' || (connectionType === 'ethernet' || connectionType === 'wifi') && bandwidth >= 12;
                    resolve({
                        bandwidth: bandwidth,
                        isBadConnection: isBadConnection,
                        isNormalConnection: isNormalConnection,
                        isFastConnection : isFastConnection
                    });
                } else {
                    let imageAddr = "https://art.pinglestudio.com/wp-content/uploads/2023/06/pingle-studio-frame-56825-6.jpg";
                    let downloadSize = 438014; //bytes
                    let MeasureByImage = () => {
                        let startTime, endTime;
                        let download = new Image();
                        download.onload = () => {
                            endTime = (new Date()).getTime();
                            let duration = (endTime - startTime) / 1000;
                            let bitsLoaded = downloadSize * 8;
                            let speedBps = (bitsLoaded / duration).toFixed(2);
                            let speedKbps = (speedBps / 1024).toFixed(2);
                            let speedMbps = (speedKbps / 1024).toFixed(2);
                            resolve({ bandwidth: speedMbps, isNormalConnection: speedMbps >= 4, isFastConnection : speedMbps >= 12 });
                        };
                        download.onerror = () => {
                            console.warn("Invalid image, or error downloading");
                            reject("Invalid image, or error downloading");
                        };

                        startTime = (new Date()).getTime();
                        let cacheBuster = "?nnn=" + startTime;
                        download.src = imageAddr + cacheBuster;
                    };
                    MeasureByImage();
                }
            });
        },
        redirectToAdminPanel() {
            window.location.href = 'https://art.pinglestudio.com/admin';
        },
    },
};

</script>

<style>
@import '@/assets/portfolio.css';
@import 'lightgallery/css/lightgallery.css';
@import 'lightgallery/css/lg-zoom.css';
</style>