export class GalleryImageObject {

    constructor(subCategoryName = null, categoryName = null, images = null, connectionSpeed = null) {
        this.images = images;
        this.connectionSpeed = connectionSpeed;
        if (this.connectionSpeed.isBadConnection === false || this.connectionSpeed.isNormalConnection ===true || this.connectionSpeed.isFastConnection === true) {
            if (subCategoryName != null) {
                this.subCategoryImageObject = {
                    getSubCategoryName: subCategoryName,
                    getCategoryName: this.getCategoryName(subCategoryName),
                    imagesObject: images,
                    subCategoriesImagesPreview: this.getSubCategoryImagesObj(subCategoryName),
                };
            } else if (categoryName != null) {
                this.categoryImageObject = {
                    getCategoryName: categoryName,
                    imagesObject: images,
                    subCategoryTitleImages: this.getTitleImagesList(categoryName),
                };
            }
        }
        else {
            if (subCategoryName != null) {
                this.subCategoryImageObject = {
                    getSubCategoryName: subCategoryName,
                    getCategoryName: this.getCategoryName(subCategoryName),
                    imagesObject: images,
                    subCategoriesImagesPreview: this.getSubCategoryImagesObj(subCategoryName),
                };
            } else if (categoryName != null) {
                this.categoryImageObject = {
                    getCategoryName: categoryName,
                    imagesObject: images,
                    subCategoryTitleImages: this.getTitleImagesListForBadConnection(categoryName),
                };
            }
        }
    }
    getCategoryName(subCategoryName) {
        for (const category of this.images) {
            if (category.subCategoryName === subCategoryName) {
                return category.categoryName;
            }
        }
        return null;
    }


    getTitleImagesList(categoryName) {
        const images = this.images.filter(item => item.categoryName === categoryName);
        const subcategoryTitleImages = [];
        images.forEach(image => {
            subcategoryTitleImages.push({
                subCategoryName: image.subCategoryName,
                url: image.url,
            });
        });
        return subcategoryTitleImages;
    }

    getTitleImagesListForBadConnection(categoryName) {
        const images = this.images.filter(item => item.categoryName === categoryName);
        const subcategoryTitleImages = [];
        images.forEach(image => {
            subcategoryTitleImages.push({
                subCategoryName: image.subCategoryName,
                url: image.urlSlow,
            });
        });
        return subcategoryTitleImages;
    }
    getSubCategoryImagesObj(subCategoryName) {
        const subCategory = this.images.find(image => image.subCategoryName === subCategoryName);
        return subCategory ? subCategory.images : [];
    }

}
