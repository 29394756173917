import { createRouter, createWebHistory } from 'vue-router'
import PortfolioGallery from "@/components/PortfolioGallery.vue";
import UserAuth from "@/components/UserAuth.vue"

const router = createRouter({
    history: createWebHistory('/'),
    routes: [
        {
            path: '/',
            name: 'Auth',
            component: UserAuth,
            props: true
        },
        {
            path: '/portfolio',
            name: 'Gallery Home',
            component: PortfolioGallery,
            props: true,
            beforeEnter: (to, from, next) => {
                const password = localStorage.getItem("password");
                if (!password) {
                    next({ name: 'Auth' });
                } else {
                    next();
                }
            },
        },
    ]
})


export default router
